import {
  Badge,
  Box,
  CssBaseline,
  Divider,
  List,
  ListItemIcon,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { FeatureToggles } from 'enums/featureToggles';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { isLocumsSelector } from 'store/selectors/authSelector';
import {
  setActivePage,
  setMenuState,
} from 'store/slices/navigation/navigationSlice';
import MenuIcon from '../../../assets/icons/NavMenuIcon/NavMenuIcon';
import { useAppDispatch } from '../../../store/configureStore';
import mapMenuIconsection from '../../../utils/mapMenuIconNameSection';
import {
  mobileNavTheme,
  StylesDivider,
  StylesLocumsDivider,
  stylesDrawerContainer,
  StylesListItem,
  StylesMenuDrawerList,
  StylesMenuIconRow,
  StylesMenuIconText,
} from './MobileNavMenu.styles';
import { theme } from 'styles/theme';
import { ADP_URL } from 'constants/passportUrls';

export const MobileNavMenu = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const drawerStyles = stylesDrawerContainer();

  const active: MenuIcons | string = useAppSelector(
    (state: RootState) => state.nav.activePage,
  );
  const isMenuOpen: boolean = useAppSelector(
    (state: RootState) => state.nav.isMenuOpen,
  );
  const unreadCount = useAppSelector(
    state => state.notifications.unreadNotificationsCount,
  );

  const userStatusTypeId: number = useAppSelector(
    state => state.userProfile.userStatusTypeId,
  );
  const assignmentData = useAppSelector(
    state => state.assignmentSummary.assignmentState,
  );
  const enableLocumsHome = useFeatureToggle(FeatureToggles.ENABLE_LOCUMS_HOME);
  const enableTimeEntry = useFeatureToggle(
    FeatureToggles.ENABLE_LOCUMS_TIME_PAY_REIMBURSEMENTS,
  );

  const handleClick = (value: MenuIcons | string) => {
    if (active !== value) {
      if (value === MenuIcons.Menu) {
        handleToggleMenu();
      } else {
        if (value === MenuIcons.Pay) {
          if (assignmentData?.items?.length === 0) {
            dispatch(setActivePage(value));
            dispatch(setMenuState(false));
            history.push('/payroll');
          } else {
            const adpWindow = window.open(
              ADP_URL,
              '_blank',
              'noopener,noreferrer',
            );
            if (adpWindow) adpWindow.opener = null;
          }
        } else {
          dispatch(setActivePage(value));
          dispatch(setMenuState(false));
          history.push(mapMenuIconsection(value));
        }
      }
    }
  };

  const isLocums: boolean = useAppSelector(isLocumsSelector);

  const icons = isLocums
    ? [
        enableLocumsHome
          ? {
              name: MenuIcons.Home,
              icon: (
                <MenuIcon
                  active={active === MenuIcons.Home}
                  icon={MenuIcons.Home}
                />
              ),
              transform: 'scale(1)',
            }
          : {},
        {
          name: MenuIcons.Assignments,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Assignments}
              icon={MenuIcons.Assignments}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.Contacts,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Contacts}
              icon={MenuIcons.Contacts}
            />
          ),
          transform: 'scale(1)',
        },
      ]
    : [
        {
          name: MenuIcons.Home,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Home}
              icon={MenuIcons.Home}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.Jobs,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Jobs}
              icon={MenuIcons.Jobs}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.Notifications,
          icon: (
            <Badge
              color="error"
              variant="dot"
              overlap="circular"
              invisible={unreadCount === 0 || !unreadCount}
            >
              <MenuIcon
                active={active === MenuIcons.Notifications}
                icon={MenuIcons.Notifications}
              />
            </Badge>
          ),
          transform: 'scale(0.8)',
        },
        {
          name: MenuIcons.Contacts,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Contacts}
              icon={MenuIcons.Contacts}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.Assignments,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Assignments}
              icon={MenuIcons.Assignments}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.TaskCenter,
          icon: (
            <MenuIcon
              active={active === MenuIcons.TaskCenter}
              icon={MenuIcons.TaskCenter}
            />
          ),
          transform: 'scale(1.2) translate(-3px,0px)',
        },
        {
          name: MenuIcons.SkillsChecklist,
          icon: (
            <MenuIcon
              active={active === MenuIcons.SkillsChecklist}
              icon={MenuIcons.SkillsChecklist}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        {
          name: MenuIcons.Resources,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Resources}
              icon={MenuIcons.Resources}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        // {
        //   name: MenuIcons.HousingTravel, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.HousingTravel}
        //       icon={MenuIcons.HousingTravel}
        //     />
        //   ),
        //   transform: 'scale(1.1) translate(-2px,0px)',
        // },
        {
          name: MenuIcons.Preferences,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Preferences}
              icon={MenuIcons.Preferences}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        userStatusTypeId !== UserStatusTypeId.LEAD
          ? {
              name: MenuIcons.ShareAndRefer,
              icon: (
                <MenuIcon
                  active={active === MenuIcons.ShareAndRefer}
                  icon={MenuIcons.ShareAndRefer}
                />
              ),
              transform: 'scale(1.1) translate(-2px,0px)',
            }
          : {},
      ];

  const iconsRows2 = isLocums
    ? enableTimeEntry
      ? [
          {
            name: MenuIcons.PayStatements,
            icon: (
              <MenuIcon
                active={active === MenuIcons.PayStatements}
                icon={MenuIcons.PayStatements}
              />
            ),
            transform: 'scale(1.1) translate(-2px,0px)',
          },
          {
            name: MenuIcons.TimeEntry,
            icon: (
              <MenuIcon
                active={active === MenuIcons.TimeEntry}
                icon={MenuIcons.TimeEntry}
              />
            ),
            transform: 'scale(1.3) translate(-4px,0px)',
          },
          {
            name: MenuIcons.Reimbursements,
            icon: (
              <MenuIcon
                active={active === MenuIcons.Reimbursements}
                icon={MenuIcons.Reimbursements}
              />
            ),
            transform: 'scale(1.1) translate(-2px,0px)',
          },
        ]
      : []
    : [
        {
          name: MenuIcons.Pay,
          icon: (
            <MenuIcon active={active === MenuIcons.Pay} icon={MenuIcons.Pay} />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        {
          name: MenuIcons.TimeEntry,
          icon: (
            <MenuIcon
              active={active === MenuIcons.TimeEntry}
              icon={MenuIcons.TimeEntry}
            />
          ),
          transform: 'scale(1.3) translate(-4px,0px)',
        },
        {
          name: MenuIcons.Reimbursements,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Reimbursements}
              icon={MenuIcons.Reimbursements}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
      ];

  const iconsRows3 = isLocums
    ? []
    : [
        // {
        //   name: MenuIcons.RewardsRecognition, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.RewardsRecognition}
        //       icon={MenuIcons.RewardsRecognition}
        //     />
        //   ),
        //   transform: 'scale(1.1) translate(-1px, 0)',
        // },
        // {
        //   name: MenuIcons.EducationTrainingCommunity, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.EducationTrainingCommunity}
        //       icon={MenuIcons.EducationTrainingCommunity}
        //     />
        //   ),
        //   transform: 'scale(1.2) translate(-2px, 0px)',
        // },
        {
          name: MenuIcons.Faq,
          icon: (
            <MenuIcon active={active === MenuIcons.Faq} icon={MenuIcons.Faq} />
          ),
          transform: 'scale(1)',
        },
        // {
        //   name: MenuIcons.Feedback,
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.Feedback}
        //       icon={MenuIcons.Feedback}
        //     />
        //   ),
        //   transform: 'scale(1)',
        // },
      ];

  const handleToggleMenu = () => {
    dispatch(setMenuState(!isMenuOpen));
  };
  const getNavigationMenu = (iconName: string) => {
    switch (iconName) {
      case MenuIcons.Faq:
        return (
          <Typography
            color={
              active === iconName ? theme.palette.system.skyBlue : 'inherit'
            }
            variant="body1"
          >
            {MenuIcons.FaqNav}
          </Typography>
        );
      case MenuIcons.Reimbursements:
        return (
          <Typography
            color={
              active === iconName ? theme.palette.system.skyBlue : 'inherit'
            }
            variant="body1"
          >
            {isLocums ? MenuIcons.Expenses : MenuIcons.ReimbursementsNav}
          </Typography>
        );
      default:
        return (
          <Typography
            color={
              active === iconName ? theme.palette.system.skyBlue : 'inherit'
            }
            variant="body1"
          >
            {iconName}
          </Typography>
        );
    }
  };

  return (
    <>
      <CssBaseline />

      {isMenuOpen && (
        <Box
          role="mobile-tablet-menu"
          aria-label="InnerMenu"
          className={drawerStyles.drawerContainer}
        >
          <List sx={StylesMenuDrawerList}>
            {icons?.reduce((prev, icon) => {
              if (Object.keys(icon).length > 0) {
                return [
                  ...prev,
                  <Box
                    role="menuitem"
                    aria-label={icon.name}
                    key={icon.name}
                    onClick={() => {
                      if (icon?.name) {
                        handleClick(icon.name);
                      }
                    }}
                    sx={StylesMenuIconRow}
                  >
                    <ListItemIcon
                      key={icon.name}
                      style={{
                        transform: icon.transform,
                      }}
                      sx={StylesListItem}
                    >
                      {icon.icon}
                    </ListItemIcon>
                    <Box sx={StylesMenuIconText}>
                      <ThemeProvider theme={mobileNavTheme}>
                        {getNavigationMenu(icon?.name ?? '')}
                      </ThemeProvider>
                    </Box>
                  </Box>,
                ];
              }
              return prev;
            }, [] as ReactElement[])}

            <Divider sx={isLocums ? StylesLocumsDivider : StylesDivider} />

            {iconsRows2.map(icon => (
              <Box
                key={icon.name}
                onClick={() => handleClick(icon.name)}
                sx={StylesMenuIconRow}
              >
                <ListItemIcon
                  key={icon.name}
                  style={{
                    transform: icon.transform,
                  }}
                  sx={StylesListItem}
                >
                  {icon.icon}
                </ListItemIcon>
                <Box sx={StylesMenuIconText}>
                  <ThemeProvider theme={mobileNavTheme}>
                    {getNavigationMenu(icon.name)}
                  </ThemeProvider>
                </Box>
              </Box>
            ))}

            <Divider sx={isLocums ? StylesLocumsDivider : StylesDivider} />

            {iconsRows3.map(icon => (
              <Box
                key={icon.name}
                onClick={() => handleClick(icon.name)}
                sx={StylesMenuIconRow}
              >
                <ListItemIcon
                  key={icon.name}
                  style={{
                    transform: icon.transform,
                  }}
                  sx={StylesListItem}
                >
                  {icon.icon}
                </ListItemIcon>
                <Box sx={StylesMenuIconText}>
                  <ThemeProvider theme={mobileNavTheme}>
                    {getNavigationMenu(icon.name)}
                  </ThemeProvider>
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};
