import { http } from 'services/BaseApi';
import { IContactResponse } from 'types/ContactResponse';

/**
 * Method to get recruiter from user contacts
 *
 * @param userId - user Id of the logged in user
 */
export const getUserContacts = async (
  userId: string,
): Promise<IContactResponse> => {
  return await http.get<string>(`/contacts/${userId}`);
};

export const getUserContactsWithDateRange = async (
  userId: string,
  startDate: string,
  endDate: string,
  isLocums: boolean,
): Promise<IContactResponse> => {
  return await http.get<string>(
    `/v2/contacts/${userId}?startDate=${startDate}&endDate=${endDate}`,
    isLocums
      ? {
          headers: { 'x-userType': 'locums' },
        }
      : {},
  );
};
