import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { SuccessResponse } from 'types/SuccessResponse';
import { EducationState } from './educationState';
import { IEducationSchool } from 'interfaces/User/IEducationSchool';

const initialState: EducationState = {
  isEditEducationSuccess: {
    success: false,
  },
  isloading: false,
  isAddEducationSuccess: {
    success: false,
  },
  isAddEducationloading: false,
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,
  isSubmitLoaderActive: false,
  isEducationError: false,
  selectedEducation: undefined,
};

const educationSlice = createSlice({
  name: 'educationSlice',
  initialState,
  reducers: {
    invokeEditEducationHistory(state, action) {
      state.isloading = true;
      state.isSubmitLoaderActive = true;
    },
    editEducationHistory(state, action) {
      state.isSubmitLoaderActive = true;
    },
    setEditEducationHistory(state, action: PayloadAction<SuccessResponse>) {
      state.isEditEducationSuccess = action.payload;
      state.isloading = false;
      state.isSubmitLoaderActive = false;
    },
    addEducationHistory(state, action) {
      state.isSubmitLoaderActive = true;
    },
    setAddEducationHistory(state, action: PayloadAction<SuccessResponse>) {
      state.isAddEducationSuccess = action.payload;
      state.isAddEducationloading = false;
      state.isSubmitLoaderActive = false;
    },
    setLoading(state) {
      state.isAddEducationloading = true;
    },
    setAddEducationSuccess(state, action: PayloadAction<SuccessResponse>) {
      state.isAddEducationSuccess = action.payload;
    },

    resetEditEducation(state) {
      state.isEditEducationSuccess.success = false;
    },
    resetAddEducation(state) {
      state.isAddEducationSuccess.success = false;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      state.error = true;
      state.errorResponse = {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        errorDetails: action.payload.errorDetails,
        errorMessage: action.payload.errorMessage,
        operationId: action.payload.operationId,
        correlationId: action.payload.correlationId,
      };
      state.downloadError = false;
    },
    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    unSetError() {
      return {
        ...initialState,
      };
    },
    setEducationError(state) {
      state.isEducationError = true;
      state.isSubmitLoaderActive = false;
    },
    resetEducationErrorState(state) {
      state.isEducationError = false;
      state.isSubmitLoaderActive = false;
    },
    setSelectedEducation(
      state,
      action: PayloadAction<IEducationSchool | undefined>,
    ) {
      state.selectedEducation = action.payload;
    },
  },
});

export const {
  invokeEditEducationHistory,
  addEducationHistory,
  setAddEducationHistory,
  editEducationHistory,
  setEditEducationHistory,
  setLoading,
  setAddEducationSuccess,
  resetEditEducation,
  resetAddEducation,
  setError,
  setErrorWithoutPayload,
  unSetError,
  setEducationError,
  resetEducationErrorState,
  setSelectedEducation,
} = educationSlice.actions;

export default educationSlice.reducer;
