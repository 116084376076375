import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { logger } from 'services/logging/appInsights';
import * as userService from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import {
  addEducationHistory,
  editEducationHistory,
  invokeEditEducationHistory,
  setAddEducationHistory,
  setEditEducationHistory,
  setEducationError,
  setError,
  setLoading,
} from 'store/slices/education/educationSlice';
import {
  getUserProfile,
  updateEducationSchools,
} from 'store/slices/user/userProfile/userProfileSlice';

const selectedClearanceItem = (state: RootState) =>
  state.actionItem.selectedClearanceItem;

function* editEducation(action) {
  try {
    const response = yield call(
      f => userService.editUserEducation(f.userId, f.editEducation),
      action.payload,
    );
    const { data } = response;
    yield put(setEditEducationHistory(data));
    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setEducationError());
    yield put(setError(err));
    logger.error(error, 'editEducation', 'educationSaga.ts');
  }
}

function* editEducationV2(action) {
  try {
    const response = yield call(
      f => userService.editUserEducationV2(f.userId, f.editEducation),
      action.payload,
    );
    const { data } = response;
    if (data?.success) {
      yield put(setEditEducationHistory(data));
      yield put(getUserProfile(action.payload.userId));
    }
    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setEducationError());
    yield put(setError(err));
    logger.error(error, 'editEducationV2', 'educationSagaV2.ts');
  }
}

function* addEducation(action) {
  try {
    const response = yield call(
      f => userService.addUserEducation(f.userId, f.addEducation),
      action.payload,
    );
    const { data } = response;
    yield put(setAddEducationHistory(data));

    if (data?.success) {
      const schoolToAdd = {
        ...action.payload?.addEducation,
        name: action.payload?.addEducation?.school,
      };
      yield put(getUserProfile(action.payload.userId));
      yield put(updateEducationSchools(schoolToAdd));

      const clearanceItem = yield select(selectedClearanceItem);
      if (clearanceItem && clearanceItem.id) {
        yield put(actionItemActions.dismissActionItemForClearanceItem());
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoading());
    logger.error(error, 'addEducation', 'educationSaga.ts');
  }
}

function* editEducationWatcher() {
  yield takeEvery(invokeEditEducationHistory.type, editEducation);
}
function* addEducationWatcher() {
  yield takeLatest(addEducationHistory.type, addEducation);
}
function* editEducationV2Watcher() {
  yield takeEvery(editEducationHistory.type, editEducationV2);
}

export function* educationSaga() {
  yield all([
    editEducationWatcher(),
    addEducationWatcher(),
    editEducationV2Watcher(),
  ]);
}
